import { inspect } from 'util'
import { omit, isObject } from 'lodash'

const ix = (data) => inspect(data, false, null)

const combineMethodHeaders = (headers, method) => {
  return Object.assign({}, headers.common, headers[method])
}

const sensitiveKeys = [
  'access_token',
  'accessToken',
  'authorization',
  'oldPassword',
  'old_password',
  'newPassword',
  'new_password',
  'password',
  'passwordConfirmation',
  'password_confirmation',
  'resetToken',
  'reset_token',
  'sessionToken',
  'session_token',
  'token',
]

const getAllOtherHeaders = (headers) => {
  const filteredHeaders = [
    'common',
    'delete',
    'get',
    'head',
    'post',
    'put',
    'patch',
  ]
  return omit(headers, filteredHeaders)
}

export const filterSensitiveValues = (data) => {
  const result = {}

  if (!data) return result

  Object.keys(data).forEach((key) => {
    if (sensitiveKeys.includes(key)) {
      result[key] = '[FILTERED]'
    } else {
      result[key] =
        data[key] && isObject(data[key])
          ? filterSensitiveValues(data[key])
          : data[key]
    }
  })

  return result
}

const mergeHeaders = (config) => {
  const methodHeaders = combineMethodHeaders(config.headers, config.method)
  const otherHeaders = getAllOtherHeaders(config.headers)
  const mergedHeaders = Object.assign(methodHeaders, otherHeaders)

  const filteredHeaders = filterSensitiveValues(mergedHeaders)

  return ix(filteredHeaders)
    .toString()
    .replace(/\r?\n|\r/g, '')
}

const parseData = (data) => {
  const filteredData = filterSensitiveValues(data)

  return ix(filteredData)
    .toString()
    .replace(/\r?\n|\r/g, '')
    .replace(/\s+/g, ' ')
}

export const requestInterceptor = (config) => {
  // if (process.env.LOG_NETWORK) {
  //   console.log(`\
  //     SATELLITE CLIENT REQUEST: ${config.method.toUpperCase()} \
  //     ${config.url} \
  //     Headers=${mergeHeaders(config)}, \
  //     Data=${parseData(config.data)} \
  //   `);
  // }

  return config
}

export const responseInterceptor = (response) => {
  // if (process.env.LOG_NETWORK) {
  //   console.log(`\
  //     SATELLITE CLIENT RESPONSE: ${response.config.method.toUpperCase()} \
  //     ${response.config.url} \
  //     StatusCode=${response.status} \
  //     Data=${parseData(response.data)} \
  //   `)
  // }

  return response
}

export const errorHandler = (error) => {
  // if (process.env.LOG_NETWORK) {
  //   console.log(`\
  //     SATELLITE CLIENT RESPONSE: ${error.config.method.toUpperCase()} \
  //     ${error.config.url} \
  //     StatusCode=${error.response.status} \
  //     Data=${parseData(error.response.data)} \
  //   `)
  // }

  return Promise.reject(error)
}

export default {
  requestInterceptor,
  responseInterceptor,
  errorHandler,
}
