import {
  find,
  has,
  isArray,
  isNull,
  isUndefined,
  map,
  mapValues,
  some,
  omit,
} from 'lodash'

const getIncluded = (relationship, included) => {
  if (isUndefined(included) || isUndefined(relationship)) {
    return relationship
  }
  if (!find(included, { type: relationship.type, id: relationship.id })) {
    return relationship
  }
  return parseItem(
    find(included, { type: relationship.type, id: relationship.id }),
    included,
    null
  )
}

const parseRelationships = (relationships, included) => {
  if (isUndefined(relationships)) return
  // eslint-disable-next-line
  return mapValues(relationships, (value, key) => {
    if (isNull(value) || isNull(value.data)) return null
    if (isArray(value.data)) {
      return map(value.data, (v) => {
        return getIncluded(v, included)
      })
    }
    return getIncluded(value.data, included)
  })
}

const parseItem = (item, included, total_records) => {
  if (isUndefined(item)) return {}
  const relationships = parseRelationships(item.relationships, included)
  const parsedItem = Object.assign(
    {},
    {
      id: item.id,
      type: item.type,
      total_records,
    },
    item.attributes && item.attributes,
    relationships && relationships,
    item.links && { links: item.links },
    item.meta && { meta: item.meta }
  )

  return parsedItem
}

const isJsonApiData = (response) => {
  const hasDoubleData = has(response, 'data.data')

  return hasDoubleData
}

const deserialize = (response) => {
  if (!isJsonApiData(response)) return response
  const { data, included, links, meta } = response.data
  const { total_records } = meta || {}
  const newData = isArray(data)
    ? map(data, (d) => parseItem(d, included, total_records))
    : parseItem(data, included, total_records)
  return {
    ...response,
    data: Object.assign(
      {},
      { data: newData },
      links && { links },
      meta && { pagination: meta }
    ),
  }
}

export default deserialize
