import { isEmpty, pick } from 'lodash'
import qs from 'qs'

export const removeEmptyAttributes = (obj) => {
  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === 'object') removeEmptyAttributes(val)
    else if (val === null || val === undefined || val === 'undefined') {
      delete obj[key]
    }
  })
  return obj
}

export const requestURLWithQuery = (url, query) => {
  if (!isEmpty(query)) {
    const cleanQuery = removeEmptyAttributes(query)
    return `${url}?${qs.stringify(cleanQuery)}`
  }
  return url
}

export const parseHeaders = (headers) => {
  const allowableHeaders = [
    'accept',
    'Accept',
    'content-type',
    'authorization',
    'x-request-id',
  ]
  return pick(headers, allowableHeaders)
}
