import axios from 'axios'
import { isEmpty, isUndefined } from 'lodash'
import {
  requestInterceptor,
  responseInterceptor,
  errorHandler,
} from './interceptors'

import { requestURLWithQuery, parseHeaders } from './helpers'

import serialize from './serializer'
import deserialize from './deserializer'
//@ts-ignore
const Cookies = require('js-cookie')

axios.interceptors.request.use(requestInterceptor, errorHandler)
axios.interceptors.response.use(responseInterceptor, errorHandler)

/*
  SatelliteClient::handler
    @method: String
    @baseURL: String
    @options: Object
      - @serializer: Object (optional)
      - @data: Object (optional)
      - @headers: Object (optional)
      - @query: Object (optional)
      - @model: string (required)
      - @submodel: string (optional)
*/
const handler = function (method, baseURL, url, options = {}) {
  const requestObject = buildRequestObject(method, baseURL, url, options)
  return axios(requestObject)
    .then((response) => {
      if (requestObject.skipDeserialize) {
        if (response.data.data) {
          return response.data.data
        } else {
          return response.data
        }
      } else {
        return deserialize(response).data.data
      }
    })
    .catch((error) => {
      if (error.constructor !== TypeError) {
        return new Promise((resolve, reject) => reject(error))
      } else {
        console.error(error)
        return error
      }
    })
}

const createHandlers = function (baseURL = '') {
  return {
    get: handler.bind(null, 'get', baseURL),
    post: handler.bind(null, 'post', baseURL),
    patch: handler.bind(null, 'patch', baseURL),
    put: handler.bind(null, 'put', baseURL),
    delete: handler.bind(null, 'delete', baseURL),
  }
}

const SatelliteClient = {
  ...createHandlers(),
  platform: createHandlers(process.env['NEXT_PUBLIC_API_URL']),
}

export const buildRequestObject = (method, baseURL, url, options: any = {}) => {
  const {
    serializer,
    headers,
    skipDeserialize,
    skipQueryPrefill,
    removeOrganizationId,
  } = options
  let { data, query } = options

  const dataIsOptions = !data && !serializer && !headers && !query

  if (dataIsOptions) {
    data = options
  } else if (serializer) {
    data = serialize(serializer, data, false)
  }

  if (isUndefined(query)) {
    query = {}
  }

  if (!skipQueryPrefill) {
    if (!query.access_token || !query.current_user_id) {
      if (localStorage) {
        const auth = localStorage.getItem('auth')
        const { accessToken, currentUserId } = JSON.parse(auth || '{}')
        const currentOrganizationId = localStorage.getItem(
          'currentOrganizationId'
        )
        if (currentUserId && !query.current_user_id) {
          query.current_user_id = currentUserId
        }

        if (!removeOrganizationId) {
          if (currentOrganizationId) {
            query.current_organization_id = currentOrganizationId
          }
        }
        if (accessToken) {
          query.access_token = accessToken
        }
      }
    }
  }

  const requestURL = requestURLWithQuery(url, query)
  // let parsedHeaders = parseHeaders(headers);

  const auth = localStorage.getItem('auth')
  const { accessToken, currentUserId } = JSON.parse(auth || '{}')

  return Object.assign(
    {
      method: method,
      url: requestURL,
      skipDeserialize,
    },
    headers && Object.keys(headers).length > 0
      ? {
          headers: Object.assign(
            {},
            { ...headers },
            accessToken && {
              authorization: `Bearer ${accessToken}`,
            }
          ),
        }
      : {
          headers: Object.assign(
            {},
            {
              Accept: 'application/vnd.api+json',
              'content-type': 'application/vnd.api+json',
            },
            accessToken && {
              authorization: `Bearer ${accessToken}`,
            }
          ),
        },
    baseURL && { baseURL },
    !isEmpty(data) && { data }
  )
}

export { SatelliteClient }
