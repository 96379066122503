import { isArray, isUndefined, map, omit, reduce } from 'lodash'
import pluralize from 'pluralize'

const createRelationships = (relationships) => {
  if (isUndefined(relationships)) return null

  const serializedRelationships = reduce(
    relationships,
    (result, value, key) => {
      // eslint-disable-next-line
      // eslint-disable-next-line
      result[key] = { data: { id: value.id, type: value.type } }

      return result
    },
    {}
  )
  // eslint-disable-next-line
  return { relationships: serializedRelationships }
}

const serializeItem = (type, item, dontOmitAttributeId) => {
  let serializedItem = Object.assign(
    {},
    item && { id: item.id },
    type && { type },
    { attributes: item },
    createRelationships(item.relationships)
  )

  if (dontOmitAttributeId) {
    serializedItem = omit(serializedItem, ['attributes.relationships'])
  } else {
    serializedItem = omit(serializedItem, [
      'attributes.relationships',
      'attributes.id',
    ])
  }

  return serializedItem
}

const serialize = (type, data, dontOmitAttributeId) => {
  let serializedData
  if (isArray(data)) {
    serializedData = map(data, (item) =>
      serializeItem(type, item, dontOmitAttributeId)
    )
  } else {
    serializedData = serializeItem(type, data, dontOmitAttributeId)
  }

  return { data: serializedData }
}

export default serialize
