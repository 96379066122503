import { cva } from 'class-variance-authority'

// #region BUTTON STYLES
const buttonStyles = cva(
  [
    'z-1 inline-flex flex-row items-center justify-center align-bottom',
    'white relative cursor-pointer whitespace-nowrap leading-5',
    '!font-jakarta-sans text-lg font-bold focus:outline-none ',
    'transition duration-300',
  ],
  {
    variants: {
      context: {
        primary:
          'tw-btn-primary from-earth-dark to-earth hover:ring-neptune-light overflow-hidden bg-gradient-to-r from-[#5225EE] via-[#3E7CF9] to-[#0FEC18] text-white hover:ring',
        primaryFlat:
          'hover:ring-neptune-light overflow-hidden bg-gradient-to-b from-[#5225EE] to-[#3C19CB] text-white hover:ring',
        secondary:
          'btn-secondary text-moon-darkest hover:ring-neptune-light overflow-hidden bg-white hover:ring',
        tertiary:
          'tw-btn-tertiary focus:shadow-outline-purple hover:ring-mars-light from-nebula to-sun overflow-hidden bg-gradient-to-r !text-white hover:ring',
        neptune:
          'bg-neptune overflow-hidden text-white hover:ring hover:ring-[#3C19CB]',
        transparent:
          'focus:shadow-outline-purple hover:ring-moon-light bg-transparent text-white ring-1 ring-white hover:ring',
        outline:
          'focus:shadow-outline-gray border border-gray-300 text-gray-600 hover:border-gray-500 focus:border-gray-500 focus:outline-none active:bg-transparent active:text-gray-500 dark:text-gray-400',
        link: 'text-gray-600 focus:outline-none dark:text-gray-400',
        success:
          'bg-titan-darkalt hover:ring-titan-light overflow-hidden text-white hover:ring',
        danger:
          'bg-mars-dark hover:ring-mars-light overflow-hidden text-white hover:ring',
        earth:
          'tw-btn-earth from-earth-dark to-earth hover:ring-earth-dark overflow-hidden bg-gradient-to-l text-white hover:ring',
        earthDark:
          'tw-btn-earth from-earth-dark to-earth hover:ring-earth-dark overflow-hidden bg-gradient-to-l text-white hover:ring',
        warning: 'bg-sun-dark hover:ring-sun-darkest text-white hover:ring',
        nebula:
          'bg-nebula hover:ring-nebula-dark overflow-hidden text-white hover:ring',
        menu: 'bg-neptune-darkalt overflow-hidden text-white',
        gray: 'hover:ring-neptune-light overflow-hidden bg-[#E9EFF8] text-[#060921] hover:ring',
        none: '',
      },
      iconSide: {
        left: '',
        right: '!pl-6 !pr-3',
      },
      iconCircle: {
        left: '!pl-2 !pr-4',
        right: '!pr-0',
        none: '',
      },
      icon: {
        with: '!py-0',
        none: '',
      },
      size: {
        tiny: 'rounded-4xl h-8 px-3 py-1 text-sm font-medium',
        small: 'rounded-4xl h-10 py-1 pl-4 pr-4 text-base',
        regular: 'rounded-4xl h-14 px-8 text-base',
        medium: 'px-6',
        large: 'rounded-5xl h-12 p-6 text-[20px]',
        larger: 'rounded-4xl',
        pagination: '',
      },
    },
    compoundVariants: [
      {
        size: 'small',
        icon: 'with',
        class: '!pl-4',
      },
      {
        size: 'small',
        icon: 'with',
        iconSide: 'right',
        class: '!pl-4 !pr-2',
      },
      {
        size: 'large',
        icon: 'with',
        class: '!py-[14px]',
      },
    ],
  }
)

//#region Button Disabled
const buttonDisabledStyles = cva(
  ['cursor-not-allowed text-[#090E2B] opacity-50'],
  {
    variants: {
      context: {
        primary: '!bg-moon-dark',
        primaryFlat: 'bg-moon-dark',
        secondary: 'bg-moon-dark ',
        tertiary:
          'bg-moon-dark hover:bg-moon-dark from-moon-dark to-moon-dark text-white',
        neptune: 'hover:!ring-moon ',
        transparent:
          'bg-moon-dark hover:bg-moon-dark from-moon-dark to-moon-dark ',
        outline: 'bg-moon-dark',
        link: 'bg-moon-dark',
        success: 'bg-moon-dark ',
        danger: 'bg-moon-dark',
        earth: 'bg-moon-dark hover:ring-moon text-white hover:ring',
        earthDark: 'bg-moon-dark hover:ring-moon text-white hover:ring',
        warning: 'bg-moon-dark hover:ring-moon text-white hover:ring',
        nebula: 'hover:!ring-moon ',
        menu: 'bg-moon-dark hover:bg-moon-dark from-moon-dark to-moon-dark ',
        gray: 'bg-inherit',
        none: '',
      },
    },
  }
)

// #region Icon
const iconStyles = cva(['z-2000 justify-start'], {
  variants: {
    size: {
      tiny: '',
      small: '',
      regular: '',
      medium: '',
      large: '',
      larger: '',
      pagination: '',
    },
    side: {
      left: 'ml-2 mr-4',
      right: 'ml-4',
    },
    circle: {
      with: 'h-100 flex items-center justify-center',
      none: '!ml-0 !mr-0',
    },
  },
})

// #region Icon circle
const iconCircleContainerStyles = cva(
  ['z-[50] flex h-full w-auto items-center justify-center py-2'],
  {
    variants: {
      size: {
        tiny: '',
        small: '',
        regular: '',
        medium: '',
        large: '',
        larger: '',
        pagination: '',
      },
      side: {
        left: 'mr-auto pl-2',
        right: 'ml-auto',
      },
    },
  }
)

const iconCircleStyles = cva(
  [
    'text-neptune-dark flex h-full items-center justify-center rounded-full bg-white',
  ],
  {
    variants: {
      size: {
        tiny: 'w-[28px]',
        small: 'h-[24px] w-[24px]',
        regular: 'w-[48px]',
        medium: 'w-[48px]',
        large: 'w-[64px]',
        larger: 'w-[64px]',
        pagination: '',
      },
    },
  }
)

//#region span
const spanCx = cva(['flex h-full items-center justify-center'], {
  variants: {
    side: {
      left: 'mr-auto pl-2',
      right: 'pr-2',
    },
    icon: {
      with: '',
      none: '',
    },
    iconWithCircle: {
      with: '!pl-1',
      none: '',
    },
    size: {
      tiny: '',
      small: 'text-sm font-semibold',
      regular: '',
      medium: '',
      large: 'text-sm',
      larger: '',
      pagination: '',
    },
  },
  compoundVariants: [
    {
      size: 'small',
      icon: 'with',
      class: 'flex flex-col text-center',
    },
    {
      size: 'regular',
      icon: 'with',
      class: 'flex flex-col text-center',
    },
    {
      size: 'large',
      icon: 'with',
      class: 'flex flex-col text-center',
    },
  ],
})

export {
  buttonStyles,
  buttonDisabledStyles,
  iconStyles,
  iconCircleContainerStyles,
  iconCircleStyles,
  spanCx,
}
