'use client'

import React, { ReactNode, useContext, useMemo } from 'react'
import { cva } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'
import classNames from 'classnames'

import {
  buttonStyles,
  buttonDisabledStyles,
  iconCircleContainerStyles,
  iconCircleStyles,
  iconStyles,
  spanCx,
} from './button-styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface Props {
  ref?: any
  children?: React.ReactNode
  /**
   * Defines if the button is disabled
   */
  disabled?: boolean
  /**
   * The size of the button
   */
  size:
    | 'tiny'
    | 'small'
    | 'medium'
    | 'regular'
    | 'large'
    | 'larger'
    | 'pagination'
    | null
    | undefined
  /**
   * The icon
   */
  icon?: any
  /**
   * Shows an icon inside the button, left aligned
   */
  iconSide?: 'left' | 'right'
  /**
   * Shows an icon inside the button, right aligned
   */
  /**
   * The style of the button
   */
  context:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'transparent'
    | 'success'
    | 'danger'
    | 'warning'
    | 'earth'
    | 'earthDark'
    | 'primaryFlat'
    | 'nebula'
    | 'neptune'
    | 'link'
    | 'gray'
    | 'menu'
  /**
   * Shows the button with circle icon styles
   */
  iconWithCircle?: boolean
  /**
   * The classnames of the icon
   */
  iconClassNames?: string
  /**
  /**
   * The classnames of the icon
   */
  iconContainerClassNames?: string
  /**
   * The classnames of the icon circle
   */
  iconCircleClassnames?: string
  /**
   * The background color of the icon circle
   */
  iconBackgroundColor?: string
  /**
   * The opacity of the icon circle
   */
  iconBackgroundOpacity?: string
  childrenClassnameOverride?: string
  spanClassnames?: string
}
export interface ButtonAsButtonProps
  extends Props,
    React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * The element that should be rendered as a button
   */
  tag?: 'button'
  /**
   * The native HTML button type
   */
  type?: 'button' | 'submit' | 'reset'
  hidden?: boolean
}

export interface ButtonAsAnchorProps
  extends Props,
    React.AnchorHTMLAttributes<HTMLAnchorElement> {
  tag: 'a'
}

export interface ButtonAsOtherProps
  extends Props,
    React.AnchorHTMLAttributes<HTMLAnchorElement> {
  tag: string
}

export type ButtonProps =
  | ButtonAsButtonProps
  | ButtonAsAnchorProps
  | ButtonAsOtherProps

const Button = function Button(props: ButtonProps) {
  const {
    children,
    size = 'regular',
    context,
    spanClassnames,
    disabled,
    onClick,
    className,
    icon,
    iconWithCircle,
    iconBackgroundColor,
    iconBackgroundOpacity,
    iconCircleClassnames,
    iconClassNames,
    iconContainerClassNames,
    iconSide,
    ref,
    ...other
  } = props

  const handleClick = (e: any) => {
    typeof onClick === 'function' && onClick(e)
  }

  const sizes = {
    small: 'sm',
    regular: 'lg',
    medium: 'lg',
    large: 'lg',
    larger: '2x',
    pagination: '2x',
  }

  const Icon = useMemo(() => {
    return (
      <div
        className={twMerge(
          iconStyles({
            size: size,
            circle: iconWithCircle ? 'with' : 'none',
          }),
          iconContainerClassNames
        )}
      >
        <FontAwesomeIcon
          className={twMerge(iconClassNames, 'font-bold')}
          //@ts-ignore
          size={sizes[size || 'large']}
          icon={icon}
        />
      </div>
    )
  }, [iconSide, iconWithCircle, icon, iconClassNames])

  const IconComponent = useMemo(() => {
    if (iconWithCircle) {
      const rgbValueObj = hexToRgb(iconBackgroundColor)
      return (
        <div
          className={twMerge(
            iconCircleContainerStyles({
              size: size,
              side: iconSide,
            }),
            iconCircleClassnames
          )}
        >
          <div
            style={{
              //@ts-ignore
              backgroundColor: rgbValueObj
                ? `rgba(${rgbValueObj['r']}, ${rgbValueObj['g']}, ${rgbValueObj['b']}, ${iconBackgroundOpacity})`
                : '',
            }}
            className={iconCircleStyles({
              size: size,
            })}
          >
            {Icon}
          </div>
        </div>
      )
    } else {
      return Icon
    }
  }, [Icon, iconWithCircle])

  return (
    <button
      ref={ref}
      className={twMerge(
        buttonStyles({
          context: disabled ? 'none' : context,
          size: size,
          iconSide: iconSide,
          iconCircle: iconWithCircle ? iconSide : 'none',
          icon: icon ? 'with' : 'none',
        }),
        disabled
          ? buttonDisabledStyles({
              //@ts-ignore
              context: disabled ? context : 'none',
            })
          : null,
        // buttonSize({
        //   //@ts-ignore
        //   type: size,
        // }),
        className
      )}
      onClick={handleClick}
      disabled={disabled}
      // {...other}
    >
      {iconSide === 'left' && <>{IconComponent}</>}
      {children && (
        <span
          className={twMerge(
            spanCx({
              side: iconSide,
              icon: icon ? 'with' : 'none',
              size: size,
            }),
            spanClassnames
          )}
          style={{ zIndex: 1 }}
        >
          {children}
        </span>
      )}
      {iconSide === 'right' && <>{IconComponent}</>}
    </button>
  )
}

export default Button

//@ts-nocheck
export function hexToRgb(hex?: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  //@ts-ignore
  hex =
    hex?.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b
    }) ?? ''

  const result = hex
    ? /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    : null
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}
